<template>
    <div class="row">
        <div class="col-12 pl-0 pr-0">
            <div class="row titleRow">
                <div class="col-5">
                <div class="row">
                    <div class="col-12">
                        <strong>Component</strong>
                    </div>
                </div>
                </div>
                <div class="col-7">
                    <div class="row">
                        <div class="col-12">
                            <strong>Suggestions</strong>
                            </div>
                    </div>
                </div>
            </div>
            <div class="row fluencyRow text-center" v-if="apiResults.type=='all'">
                <div class="col-5">
                <div class="row">
                    <div class="col-12">
                        <Progress strokeColor="#02164e" :transitionDuration="2000" :radius="45" :strokeWidth="15" :value="graphFlueScore">
                            <div class="content">{{flueScore}}/90</div>
                            <template v-slot:footer>
                                <b>Fluency</b>
                            </template>
                        </Progress>
                    </div>
                </div>
                </div>
                <div class="col-7">
                    <div class="row" style="height:100%">
                        <div class="col-12 justify-content-center align-self-center">
                            <ul>
                                <li><strong>A low score in Fluency may be because of</strong></li>
                                <li>1) Speaking too slow or too fast.</li>
                                <li>2) Speaking with too many pauses</li>
                                <li>3) Discontinuous speech</li>
                                <li class="helpli"><strong>Seek help from PTE Classes Professional tutors</strong></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pronunciationRow text-center" v-if="apiResults.type=='all'">
                <div class="col-5">
                <div class="row">
                    <div class="col-12">
                        <Progress strokeColor="#f3b119" :transitionDuration="2000" :radius="45" :strokeWidth="15" :value="apiResults.quality_Score">
                            <div class="content">{{proScore}}/90</div>
                            <template v-slot:footer>
                                <b>Pronunciation</b>
                            </template>
                        </Progress>
                    </div>
                </div>
                </div>
                <div class="col-7">
                    <div class="row" style="height:100%">
                        <div class="col-12 justify-content-center align-self-center">
                            <ul>
                                <li><strong> A low score in Pronunciation may be because of</strong></li>
                                <li>1) Speaking too slow or too fast.</li>
                                <li>2) Speaking with too many pauses</li>
                                <li>3) Discontinuous speech</li>
                                <li>4) Tone variation or too many stresses</li>
                                <li>5) Unrecognized word pronunciation</li>
                                <li class="helpli"><strong>Seek help from PTE Classes Professional tutors</strong></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row contentRow text-center">
                <div class="col-5">
                <div class="row">
                    <div class="col-12" v-if="apiResults.type=='all'">
                        <Progress strokeColor="#104e94" :transitionDuration="2000" :radius="45" :strokeWidth="15" :value="contScore">
                            <div class="content">{{apiResults.content_Score}}/90</div>
                            <template v-slot:footer>
                                <b>Content</b>
                            </template>
                        </Progress>
                    </div>
                    <div class="col-12" v-else>
                        Content <strong> {{apiResults.marks}}/1 </strong>
                    </div>
                </div>
                </div>
                <div class="col-7" v-if="apiResults.type=='all'">
                    <div class="row" style="height:100%">
                        <div class="col-12 justify-content-center align-self-center">
                             <ul>
                                <li><strong>HIGHLY EFFICIENT PTE Classes AI Algorithm</strong></li>
                             </ul>
                        </div>
                    </div>
                </div>
                <div class="col-7" v-else>
                    <div class="row" style="height:100%">
                        <div class="col-12 justify-content-center align-self-center">
                             <ul>
                                <li>Unrecognized word pronunciation could also result in loss of marks.</li>
                             </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="apiResults.type=='all'">
                <div class="col-12 pt-1 pb-1" style="background: #b2b8c9;">
                    <p>Max Score: 90,   Your Score: <strong :class="currentClass">{{overAllScore}}</strong></p>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-12 pt-1 pb-1" style="background: #b2b8c9;">
                    <p>Max Score: 1,   Your Score: <strong :class="currentClass">{{apiResults.marks}}</strong></p>
                </div>
            </div>
            <div class="row">
                <div class="audio-container col-12 left mb-4 mt-4">
                    <audio :src="apiResults.src" controls />
                </div>
            </div>
            <div class="row textRow" >
                <div class="col-12" v-if="apiResults.type=='all'">
                    <span class="transcriptHeading">AI Speech Conversion</span>
                    <div style="display:inline-block;float:right;"> 
                        <b-badge variant="success" class="p-2 mr-2">Good</b-badge>
                        <b-badge variant="warning" class="p-2 mr-2">Average</b-badge>
                        <b-badge variant="danger" class="p-2 mr-2">Bad</b-badge>
                    </div>
                </div>
                <div class="col-12 mt-2" v-if="apiResults.type=='all'">
                    <div class="singleWords" >
                        <p v-html="transcript"></p>
                        </div>
                    <!-- <div class="singleWords" v-for="(item,i) in apiResults.word_Score_List" :key="i">
                        <div class="singleWords" :class="{'text-danger':(item.quality_score<70),'text-warning':(item.quality_score>=70 & item.quality_score<90),'text-success':(item.quality_score>=90)}" :id="i" >{{item.word}}&nbsp;</div>
                    </div> -->
                </div>
                <div class="col-12 mt-2" v-if="apiResults.type!='all'">
                    <span class="transcriptHeading">AI Speech Conversion</span>
                </div>
                <div class="col-12 mt-2" v-if="apiResults.type!='all'">
                    <div class="singleWords" >
                        <p v-html="apiResults.transcript"></p>
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Progress from "easy-circular-progress";
import $ from "jquery";
export default {
    components: {
        Progress
    },
    props:['apiResults'],
    data(){
        return {
            apiOutPut: this.apiResults,
            proScore:0,
            flueScore:0,
            contScore:0,
            overAllScore:0,
            currentClass:'text-success',
            transcript:'',
            graphFlueScore:0,
        }
    },
    beforeMount(){
        if(this.apiResults.transcript!='') return this.transcript = this.apiResults.transcript
        const wordCount=this.apiResults.word_Score_List.length
        if(wordCount!=null)
        {
            for (let index = 0; index < wordCount; index++) {
                ////debugger
                const element = this.apiResults.word_Score_List[index];
                let word=element.word
                if(element.ending_punctuation!=null)
                {
                    word+=element.ending_punctuation
                }
                if(index<wordCount-1){
                    word+=' '
                }
                if(element.quality_score>79)
                {
                    this.transcript +='<span id="'+index+'" class="text-success">'+word+'</span>'
                }
                // else if(element.quality_score>79)
                // this.transcript += word
                else if(element.quality_score >65)
                this.transcript +='<span id="'+index+'" class="text-warning">'+word+'</span>'
                else
                this.transcript +='<span id="'+index+'" class="text-danger">'+word+'</span>'
            }
        }
        
    },
    created(){
        if(this.apiResults.type=='all')
        {
            
        
        // Quality score if from 100 so I deducted 10 points
        //this.proScore =(this.apiResults.quality_Score>10)?this.apiResults.quality_Score-10:this.apiResults.quality_Score
        this.proScore =this.apiResults.quality_Score
        this.proScore=(this.proScore>90)?90:this.proScore;
        // Content score is from 90 so to present property I added 10
        this.contScore = this.apiResults.content_Score+10;
        this.flueScore = this.apiResults.pte_Estimate
        if(this.flueScore<77)
        {
            this.flueScore+=10 // as Aneel bhai decided to ease fluency marking criteria
        }
        else{
            this.flueScore=90
        }
        this.flueScore=(this.flueScore>90)?90:this.flueScore;
        this.graphFlueScore=this.flueScore+10
        if(this.apiResults.content_Score==0)
        {
            this.flueScore=0
            this.graphFlueScore=0
            this.contScore =0
            this.proScore=0
            this.apiResults.quality_Scor=0
        }
        // for easing marking criteria of fluency I added 10 in pte_Estimate
        this.overAllScore =Math.round((this.flueScore+this.proScore+this.apiResults.content_Score)/3)
        // if(this.apiResults.content_Score<50)this.overAllScore-15
        // else if(this.apiResults.content_Score<70)this.overAllScore-10

        // Pte estimated score is from 90 so to present property I added 10
        //this.flueScore +=10;
        if(this.overAllScore >70) this.currentClass ='text-success'
        else if(this.overAllScore < 70 & this.overAllScore>50) this.currentClass ='text-warning'
        else if(this.overAllScore < 50) this.currentClass ='text-danger'
        var vm = this
            if(this.apiResults.text !=''){
                $.each(this.apiResults.word_Score_List,function(index,item){
                if(!vm.apiResults.text.includes(item.word))
                {
                    item.quality_score=20
                }
                })
            }
        }
        else{
             if(this.apiResults.marks < 1) this.currentClass ='text-danger'
        }

        // as we changed the path of file beacuse of mobile app
if(this.apiResults.src!=null | this.apiResults.src!=''){
   let src= this.apiResults.src.split('audio')[1];
   this.apiResults.src='audio'+src;
   console.log(this.apiResults.src);

}
        //this.apiResults.src=this.apiResults.src.replace("../../vhosts/pteclasses.com/practice.pteclasses.com/","");
    }
}
</script>
<style scoped>
.titleRow
{
background: #b2b8c9;
min-height: 30px;
text-align: center;
line-height: 30px;
font-size: 15px;
}
.fluencyRow, .pronunciationRow, .contentRow{
    padding: 1rem;
border-bottom: 1px solid #ccc;
}
ul{
    text-align: left!important;
list-style: none!important;
}
.helpli{
color: #737272;
margin-top: 10px;
}
.singleWords{
    display: inline;
}
.textRow
{
margin-top: 5px !important;
margin-bottom: 5px !important;
border-top: 1px solid #ccc;
padding-top: 10px;
padding-bottom: 10px;
}
.badge
{
    font-size: 13px;
}
.transcriptHeading
{
font-size: .8rem;
font-size: 1rem;
font-weight: 500;
padding-top: 12px;
display: inline-block;
}
</style>
<style>
.modal-title
{
text-align: center;
width: 100%;
letter-spacing: 3px;
}
</style>